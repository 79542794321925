import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { Provider } from "./components/Context";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import "../node_modules/video-react/dist/video-react.css";
import AsyncStorage from '@callstack/async-storage';
import conf from "./api/config";

const EntryPage = React.lazy(() => import('pages/Mlugha/EntryPage'));
const NewUserPage = React.lazy(() => import('pages/Mlugha/NewUserPage'));
const InfoPayPage = React.lazy(() => import('pages/Mlugha/InfoPayPage'));
const UserDashboardPage  = React.lazy(() => import('pages/Mlugha/UserDashboardPage'));
const UserRenewPage = React.lazy(() => import('pages/Mlugha/UserRenewPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user : {},
      is_logged_in : false,
    };
    AsyncStorage.getItem(conf.secret)
    .then((results) => {
      // console.log('active user', results);
      if( results !== null )
      {
        let obje_results = JSON.parse(results);
        this.setState({
          user : obje_results.data,
          is_logged_in : true,
        });
      }
      // console.log('from storage', results);
    })
    .catch((error) => {
      this.setState({
        user : {},
        is_logged_in : false,
      });
      console.log('from storage error', error);
    });
  }
  switchStateHandler = newState => {
    // console.log('new state', newState);
    this.setState({
      user : newState.user,
      is_logged_in : newState.is_logged_in,
    });
  };
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Provider value={{
            state: this.state,
            switchStateHandler: e => this.switchStateHandler(e),
          }}>
          <Switch>
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                {!this.state.is_logged_in && (
                    <div>
                      <Route exact path="/" component={EntryPage} />
                      <Route exact path="/new/account" component={NewUserPage} />
                      <Route exact path="/new/account/pay" component={InfoPayPage} />
                    </div>
                )}
                {this.state.is_logged_in && (
                  <div>
                    <Route exact path="/" component={UserDashboardPage} />
                    <Route exact path="/child/home/renew/account" component={UserRenewPage} />
                  </div>
                )}
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
          </Provider>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
