import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

import SourceLink from 'components/SourceLink';

const Footer = () => {
  const cc = new Date().getFullYear();
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
        © {cc} {process.env.REACT_APP_NAME} <SourceLink href=""><small>By {process.env.REACT_APP_DEV}</small></SourceLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
