import Avatar from 'components/Avatar';
import logo200Image from 'assets/img/logo/logo_200.gif';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { Consumer } from "../../components/Context";
import withBadge from 'hocs/withBadge';
import AsyncStorage from '@callstack/async-storage';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import conf from "../../api/config";
import { Redirect } from 'react-router-dom';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  signOut = () => {
    AsyncStorage.removeItem(conf.secret)
    .then((done) => {}).catch((error) =>{});
    window.location.href = '/';
    return;
  };
  goTo = () => {
    window.location.href = '/';
  }
  openProfile = () => {
    window.location.href = '/';
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { isNotificationConfirmed } = this.state;
    return (
      <Consumer>
      { context => (
        <Navbar light expand className={bem.b('bg-white')}>
          <Nav navbar>
            <img
              src={logo200Image}
              width="120"
              height="70"
              className="pr-2"
              alt=""
            />
          </Nav> 
          
          <Nav navbar>
          { context.state.user === undefined && (this.signOut())}
          { parseInt(context.state.user.is_active) == 1 && (
            <h1 className="top-h1">M-lugha Account: {context.state.user.email}</h1>
          )}
          </Nav> 

          { parseInt(context.state.user.is_active) !== undefined && (
            <Nav navbar className={bem.e('nav-right')}>
              <NavItem>
                <NavLink id="Popover2">
                  <Avatar
                    onClick={this.toggleUserCardPopover}
                    className="can-click"
                  />
                </NavLink>
                <Popover
                  placement="bottom-end"
                  isOpen={this.state.isOpenUserCardPopover}
                  toggle={this.toggleUserCardPopover}
                  target="Popover2"
                  className="p-0 border-0"
                  style={{ minWidth: 250 }}
                >
                  <PopoverBody className="p-0 border-light">
                    <UserCard
                      title={context.state.user.account_name}
                      subtitle={ 'Account: ' + context.state.user.email }
                      text={ 'Child account'}
                      className="border-light"
                    >
                      <ListGroup flush>
                        <ListGroupItem tag="button" action className="border-light">
                          <MdPersonPin /> Profile
                        </ListGroupItem>
                        <ListGroupItem onClick={this.signOut} tag="button" action className="border-light">
                          <MdExitToApp /> Signout
                        </ListGroupItem>
                      </ListGroup>
                    </UserCard>
                  </PopoverBody>
                </Popover>
              </NavItem>
            </Nav>
          )}
        </Navbar>
      )}
      </Consumer>
    );
  }
}

export default Header;
